@font-face {
    font-family: tropical;
    src: url(hpunk.ttf); /* Hawaiian Punk font created by Sharkshock on 1001fonts.com: https://www.1001fonts.com/hawaiian-punk-font.html */
}

body {
    font-family: tropical;
    letter-spacing: 2px;
}
.default {
    color:rgb(33, 117, 8);
}

.boldText {
    font-weight: bold;
    font-size: 20px;
}

.smallCardContainer {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    flex-direction: row;
}
.smallCard {
    text-align: center;
    font-size: 75%;
    margin: 10px;
}
.smallCard > img {
    width: 200px;
    height: 200px;
    margin: 0 auto;
    display: block;
    border: 10px solid #FFFFFF;
}

.navbar {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    flex-direction: row;
    font-size: 22px;
}

.maxWidthContainer {
    max-width: 1190px;
    margin: auto;
}

.homeHeader {
    text-align: center;
}
.homeContent {
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    width: 92%;
    margin: 2% 4%;
}

.largeCardHeader {
    text-align: center;
}
.largeCardHeader > .italics {
    font-family: Arial, Helvetica, sans-serif;
    font-style: italic;
}
.largeCardText {
    font-family: Arial, Helvetica, sans-serif;
}
.largeCardImage {
    max-width: 50%;
    border: 10px solid #FFFFFF;
    margin: 0 25%;
}

@media only screen and (max-width: 480px) {
    .largeCardImage {
        max-width: 90%;
        margin: 0 2.5% 0 2.5%;
    }
}
@media only screen and (max-width: 700px) {
    #navigation {
        margin: 10px;
    }
}

.sidenav {
    height: 100%; /* 100% Full-height */
    width: 0; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 1; /* Stay on top */
    top: 0; /* Stay at the top */
    left: 0;
    background-color: #FFDFA3; /* Black*/
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 60px; /* Place content 60px from the top */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
  }
  
  .sidenav a {
    margin-top: 10px;
    padding: 8px 32px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color:rgb(33, 117, 8);
    display: block;
    transition: 0.3s;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  
  .hamburger {
    width: 35px;
    height: 5px;
    background-color:rgb(33, 117, 8);
    margin: 6px 0;
  }
  
  #navigation {
    position: absolute;
    z-index: 2;
  }
  
  @media only screen and (min-width: 701px) {
      #navigation {
          margin: 25px;
      }
  }

  @media screen and (max-height: 450px) {
    .sidenav {
      padding-top: 15px;
    }
    .sidenav a {font-size: 18px;}
  }